<template>
  <div>
    <section id="login">
      <v-container class="login_container">
        <v-row class="d-flex flex-column justify-center align-center">
          <img
            class="pa-10"
            :src="require(`@/assets/logo.svg`)"
            alt="app logo"
          />
          <s-card class="login-card col-12" outlined>
            <h3 class="login-card__header align-center text-center">
              Working ...
            </h3>
            <div class="block_loader d-flex align-center justify-center">
              <v-progress-circular
                indeterminate
                class="ma-auto"
                color="primary"
                :size="20"
                :width="3"
              />
            </div>
          </s-card>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import jwt_decode from 'jwt-decode'
export default {
  name: 'Login',
  props: {
    token: {
      type: String,
      default: null,
    },
  },
  mounted() {
    if (!this.token) this.logout()

    try {
      jwt_decode(this.token)
    } catch (error) {
      this.logout()
      return
    }

    localStorage.setItem('jwt', this.token)
    this.$router.push({ name: 'dashboard' })
  },
  methods: {
    logout() {
      // clear any token set
      localStorage.clear('jwt')
      this.$router.push({ name: 'login' })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';
#login {
  @include center-children;

  & .login_container {
    @include auth-card-dimension;
  }
}

#logo {
  @include auth-logo;
}

.login-card {
  @include auth-card;
}

.signup-link {
  margin-top: 18px;
}

.forgotpass-link {
  @include black-link;
}
.login-card__header {
  @include auth-header;
}
</style>
